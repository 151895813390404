<template>
  <svg
    v-bind="$attrs"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle
      cx="12"
      cy="12"
      r="12"
      fill="#6CD7AA"
    />
    <path d="M8.235 16.132C7.45767 16.132 6.79033 15.967 6.233 15.637C5.67567 15.2997 5.24667 14.834 4.946 14.24C4.64533 13.6387 4.495 12.9457 4.495 12.161C4.495 11.3763 4.64533 10.6833 4.946 10.082C5.24667 9.48067 5.67567 9.01133 6.233 8.674C6.79033 8.33667 7.45767 8.168 8.235 8.168C9.159 8.168 9.91433 8.399 10.501 8.861C11.095 9.31567 11.4653 9.95733 11.612 10.786H10.061C9.96567 10.368 9.76033 10.0417 9.445 9.807C9.137 9.565 8.72633 9.444 8.213 9.444C7.50167 9.444 6.94433 9.686 6.541 10.17C6.13767 10.654 5.936 11.3177 5.936 12.161C5.936 13.0043 6.13767 13.668 6.541 14.152C6.94433 14.6287 7.50167 14.867 8.213 14.867C8.72633 14.867 9.137 14.757 9.445 14.537C9.76033 14.3097 9.96567 13.998 10.061 13.602H11.612C11.4653 14.394 11.095 15.0137 10.501 15.461C9.91433 15.9083 9.159 16.132 8.235 16.132Z" fill="black" />
    <rect
      x="14"
      y="8"
      width="7"
      height="2"
      rx="1"
      fill="white"
    />
    <rect
      x="14"
      y="11"
      width="7"
      height="5"
      rx="1"
      fill="white"
    />
  </svg>
</template>
